













































import { defineComponent, ref, computed } from '@vue/composition-api';

import { useActions, useGetters } from 'vuex-composition-helpers';

import { CREATE_OR_SAVE_CAR, GET_CARS } from '@/store/car.actions';

import { VForm } from '@/types/form.type';

import { CarEntry } from '@/models/car.model';

interface CarSelectEntry {
  value: string;
  text: string;
}

export default defineComponent({
  name: 'ReportForm',
  props: {},
  components: {},
  setup: () => {
    const getters = useGetters([GET_CARS]);

    // get cars
    const cars = getters[GET_CARS];
    const valid = ref(false);
    const form = ref();
    const actions = useActions([CREATE_OR_SAVE_CAR]);
    const createSaveCarAction = actions[CREATE_OR_SAVE_CAR];

    const selectedCar = ref('');
    const carName = ref('');

    const sendForm = async () => {
      const vform = form?.value as VForm;
      if (!vform?.validate()) {
        return;
      }

      const res = await createSaveCarAction({
        _id: selectedCar.value.length > 0 ? selectedCar.value : undefined,
        name: carName.value
      } as CarEntry);
      if (res) {
        vform?.resetValidation();
      }
    };

    const nameRules = ref([
      (v: string) => (!!v && v.length > 0) || 'Bitte Namen angeben'
    ]);

    const carValues = computed(() => [
      {
        value: '',
        text: '- Neues Fahrzeug anlegen -'
      },
      ...cars.value.map(
        (c: CarEntry) =>
          ({
            value: c._id,
            text: c.name
          } as CarSelectEntry)
      )
    ]);

    const selectedCarChanged = () => {
      if (selectedCar.value.trim().length === 0) {
        carName.value = '';
        return;
      }

      carName.value = carValues.value.find(
        (c: CarSelectEntry) => c.value === selectedCar.value
      )?.text;
    };

    return {
      form,
      valid,
      location,
      sendForm,
      nameRules,
      cars,
      selectedCar,
      carName,
      carValues,
      selectedCarChanged
    };
  }
});
